import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import api from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import NotyfContext from "../../contexts/NotyfContext";
import useAuth from "../../hooks/useAuth";
import LastAttendance from "../../components/LastAttendance";
import * as queryString from "query-string";

const ScanResult = () => {

  const [data, setData] = useState(null);
  const [result, setResult] = useState(null);
  const [type, setType] = useState('Asset');
  const [userDetail, setUser] = useState('Asset');
  const [showModal, setShowModal] = useState(false);
  const [showAttendance, setShowAttendance] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation()
  const { id } = queryString.parse(location.search);
  const notify = useContext(NotyfContext);

  useEffect(() => {
    api.get(`qrcodes/item/${id}`).then(res => {
      setResult(res.data);
      getDetail(res.data)
    });
  }, []);

  useEffect(() => {
    if (user) getUserDetail()
  }, [user])

  const getUserDetail = async () => {
    let obj = await api.get(`people/${user?.id}`)
    setUser(obj.data)
  }

  const getDetail = (data) => {
    api.get(`${getType(data)}/${data.id}`).then(res => {
      setData(res.data);
    });
  };

  const getType = (data) => {
    if (data?.type === "asset") {
      setType('Asset')
      return "assets";
    }
    if (data?.type === "zone") {
      setType('Zone')
      return "zones";
    }
    if (data?.type === "person") {
      setType('Person')
      return "people";
    }
  };

  const toDetail = () => {
    navigate(`/${getType(result)}/edit?id=${data?.id}`)
  }

  const toTask = () => {
    navigate(`/${getType(result)}/edit?id=${data?.id}&direct=tasks`)
  }

  const viewAttendance = () => {

    api.post(`${getType(result)}/attendances/${data?.id}/log`).then(res => {
      notify.open({
        type: "success",
        message: "Changes Saved"
      });
      getUserDetail().then(() => {
        setShowAttendance(true);
      })
    })
  }

  return <React.Fragment>
    <Helmet defer={false}  title="Scan Result" />
    <Container fluid className="p-0">
      {data && <h1 className="h3 mb-3">{`${data.compoundName} ${data.reference ? `(${data.reference})` : ""}`}</h1>}
      {result && data && <Row className="mb-2">
        <Col className="mb-3" sm={12} md={3}>
          <Button onClick={() => toDetail()} className="me-2">View This {type}</Button>
        </Col>
        <Col className="mb-3" sm={12} md={3}>
          <Button onClick={() => toTask()} className="me-2">Complete a Task</Button>
        </Col>
      </Row>}
      <Modal show={showModal} onHide={() => {setShowModal(false); setShowAttendance(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Please confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showAttendance ? <div>
            {userDetail.lastAttendance && <LastAttendance title={'Your Attendance'} profile={userDetail} />}
          </div> : <div>Are you sure you want to record attendance ?</div>}
        </Modal.Body>
        <Modal.Footer>
          {!showAttendance && <Button onClick={() => viewAttendance()}>Confirm</Button>}
          <Button onClick={() => {setShowModal(false); setShowAttendance(false)}} variant="secondary">Cancel</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  </React.Fragment>;
};
export default ScanResult;