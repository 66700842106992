import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import api from "../../api";
import moment from "moment-timezone";
import CommonTable from "../../components/tables/CommonTable";
import { dateFromNow, downloadFile } from "../../utils/staticMethods";

const columns = [
  {
    Header: "Name",
    accessor: "childName",
    disableSortBy: true,
    Cell: cell => (
      <div className="d-flex align-items-center"><NavLink to={{
        pathname: `/${cell.row.original.childType === "person" ? "people" : `${cell.row.original.childType}s`}/edit`,
        search: `?id=${cell.row.original.childId}`
      }}>{cell.row.original.childName + `${cell.row.original.childReference ? ` (${cell.row.original.childReference})` : ""}`}</NavLink>
        {cell.row.original.childHashTags && cell.row.original.childHashTags.map(item => <div key={item} className="ms-1 badge bg-primary me-1">{item}</div>)}
      </div>
    )
  },
  {
    Header: "Arrived",
    accessor: "id",
    Cell: cell => (
      <span>{dateFromNow(cell?.row?.original?.start)}</span>
    ),
    disableSortBy: true
  },
  {
    Header: "Time",
    accessor: "start",
    Cell: cell => (
      <span>{moment(cell?.value).format(("DD/MM/YY HH:mm"))}</span>
    )
  }
];

const ZoneOverviewTab = ({ lastPosition, id, compoundName, icon, trackers }) => {

  const [count, setCountParent] = useState(0);
  const [filterResult, setFilter] = useState('');

  const exportFile = () => {
    api.get(`zones/livecontents/${id}/export?filter=${filterResult}`, { responseType: "blob" }).then(res => downloadFile(res));
  }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h4>
          <FontAwesomeIcon icon={faCalendarCheck} fixedWidth className="fa-fw" />
          <span className="ms-1">Current Contents {typeof(count) === 'string' && `(${count})`}</span>
        </h4>
        {compoundName && <div>
          <Button className="me-1" disabled={Number(count) <= 0} onClick={() => exportFile()}  variant="success">Export</Button>
          <NavLink to={{pathname: "/report/attendance", search: `?id=${id}&name=${encodeURIComponent(compoundName)}&type=zone`}}><Button>View History</Button></NavLink>
        </div>}
      </div>
      <CommonTable filterResult={e => setFilter(e)} autoRefresh hideAuto bordered setCountParent={setCountParent} apiName={`zones/livecontents/${id}`} columns={columns} />
    </React.Fragment>
  );
};
export default ZoneOverviewTab;