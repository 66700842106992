import React from "react";
import { Card, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Email = () => {
  return <React.Fragment>
    <Col lg={4} className="mb-4">
      <Card className="flex-fill w-100 h-100 p-3">
        <h4>Email Us</h4>
        <div>
          <p className="mb-0">Email support is available in UK business hours and we'll respond as quickly as possible.</p>
          <div>
            <a className="btn btn-default" href="mailto:support@traclogik.co.uk"><FontAwesomeIcon className="text-primary" icon={faEnvelope}/> Email Support »</a>
          </div>
        </div>
      </Card>
    </Col>
  </React.Fragment>;
};
export default Email;