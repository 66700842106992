import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import api from "../../api";
import moment from "moment-timezone";
import clock from "../../assets/img/svgs/clock.svg";
import pointer from "../../assets/img/svgs/pointer.svg";

const AssetBatteryLevel = ({asset, assetId}) => {

  const [last, setLast] = useState(null)
  const [degree, setDegree] = useState(0)
  const [level, setLevel] = useState('')

  useEffect(() => {
    if(asset) {
      getData();
      getRotate(asset?.lastPosition?.supply)
    }
  }, [assetId]);

  const getRotate = (value) => {
    let degreeVal = 0
    let levelVal = ''
    if(value > 13000) {
      degreeVal = 60
      levelVal = 'High'
    } else if (value > 12001 && value <= 13000){
      degreeVal = 20
      levelVal = 'Medium'
    } else if(value >=11000 && value <= 12000) {
      degreeVal = -20
      levelVal = 'Low'
    } else if (value < 11000 && value >= 0 && value !== null){
      degreeVal = -60
      levelVal = 'Exchange'
    } else {
      degreeVal = -60
      levelVal = 'Waiting for data'
    }
    setDegree(degreeVal)
    setLevel(levelVal)
  }

  const getData = () => {
    api.get(`assets/batteryexchanges/${assetId}`).then(r => {
      if(r.data.length > 0) {
        setLast(moment(r.data[0].start).format("DD/MM/YYYY HH:mm"))
      }
    })
  }

  return <React.Fragment>
    <Card className="border flex-grow-1">
      <Card.Header className="pb-0">
        <Card.Title>
          Asset Battery Level
        </Card.Title>
      </Card.Header>
      {asset ? <Card.Body style={{minHeight: '200px'}} className="d-flex flex-column align-item-center justify-content-between">
          <div className="position-relative" style={{height: '100px'}}>
            <img style={{height: '100px', left: '50%', transform: 'translateX(-50%)'}} className="position-absolute" src={clock} alt={'clock'} />
            <img style={{height: '30px', transformOrigin: '15px 24px', left: '50%', bottom: '20px', transform: `translateX(-50%) rotate(${degree}deg)`}} src={pointer} alt={'pointer'} className="position-absolute" />
          </div>
          <h6 className="mt-2 text-center">{ level }</h6>
        <div className="text-center mb-2">
          {level && asset.lastPosition && asset.lastPosition.supply !== null && <span>{asset.lastPosition.supply} Mv</span>}
        </div>
          <div className="text-center mb-2">
            Last Exchange - {last ? <span>{last}</span> : <span>Waiting...</span>}
          </div>
      </Card.Body> : <Card.Body style={{minHeight: '200px'}} className="d-flex align-item-center justify-content-center">
        <span className="d-flex align-items-center">Waiting for data</span>
       </Card.Body>}
    </Card>
  </React.Fragment>
}
export default AssetBatteryLevel