import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container, Modal } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/useAuth";
import DeviceForm from "../../components/DeviceForm";
import api from "../../api";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { Grid, Link, Wifi } from "react-feather";
import DeviceDetail from "../../components/deviceInfo/DeviceDetail";
import NotyfContext from "../../contexts/NotyfContext";
import moment from "moment-timezone";

let source1 = null;
let source2 = null;
const Readers = () => {
  const [resultCount, setResultCount] = useState(0);
  const [name, setName] = useState(null);
  const childRef = useRef();
  const navigate = useNavigate();
  const [showModal, setShow] = useState(false);
  const [showEditModal, setShowEdit] = useState(null);
  const { user } = useAuth();
  const notify = useContext(NotyfContext);
  const [customerOption, setCustomer] = useState([]);
  const [deviceOption, setDevice] = useState([]);
  const curTime = useRef(moment())

  const columns = [
    {
      Header: "#Serial",
      accessor: "serial",
      Cell: (cell) => {
        if (user?.role === 'Root') {
          return <span>
            <NavLink to={{ pathname: "/readers/edit", search: `?id=${cell.row.original.id}` }}>{cell.value}</NavLink>
            {cell.row.original.wirepasNetworkId && <Grid className="ms-2" size={16} />}
            {cell.row.original.wirepas && <Link className="ms-1" size={16} />}
          </span>;
        } else {
          return <span onClick={() => setShowEdit(cell.row.original)} className="text-primary cursor-pointer">{cell.value}</span>
        }
      }
    },
    {
      Header: "Model",
      accessor: "model",
      disableSortBy: true,
    },
    {
      Header: "Name",
      accessor: "deviceName",
    },
    {
      Header: "Last Seen",
      accessor: "lastReport",
      type: "dateFromNow"
    },
    {
      Header: "Customer",
      accessor: "customerName",
      show: user?.role === "Root",
      disableSortBy: true,
      Cell: cell => (<NavLink
        to={{ pathname: `/customers/edit`, search: `?id=${cell.row?.original?.customerId}` }}>{cell.value}</NavLink>)
    },
    {
      Header: "Linked",
      accessor: "customerId",
      type: "linkStatus",
      disableSortBy: true
    }
  ];

  useEffect(() => {
    if (user?.role === 'Root') {
      getCustomers();
      getProfiles();
    }
  }, [user])

  useEffect(() => {
    return() => {
      if(source1) {
        source1.cancel('request canceled');
      }
      if(source2) {
        source2.cancel('request canceled');
      }
    }
  }, [])

  const getCustomers = () => {
    if(source1){
      source1.cancel('request canceled');
    }
    source1 = axios.CancelToken.source();
    api.get(`customers?page=1&limit=999`, {
      cancelToken: source1.token
    }).then(res => setCustomer(res.data)).catch(() => {
      setCustomer([])
    })
  }
  const getProfiles = () => {
    if(source2){
      source2.cancel('request canceled');
    }
    source2 = axios.CancelToken.source();
    api.get(`deviceprofiles?page=1&limit=999`, {
      cancelToken: source2.token
    }).then(res => setDevice(res.data)).catch(() => setDevice([]))
  }

  const updateData = () => {
    childRef.current.updateTable(1);
    setShow(false);
  }

  const onSave = () => {
    let obj = JSON.parse(JSON.stringify(showEditModal))
    obj.deviceName = name
    api.put(`readers`, obj)
      .then(res => {
        notify.open({
          type: 'success',
          message: 'Changes Saved'
        })
        childRef.current.updateTable()
        setShowEdit(null)
      });
  }

  return (
    <React.Fragment>
      <Helmet defer={false}  title="Readers" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="h3 mb-0"><Wifi className='me-2' />Readers {typeof(resultCount) === 'string' && `(${resultCount})`}</h1>
          <div className="d-flex flex-column flex-sm-row">
            {user?.role === "Root" &&
            <Button className="me-sm-1" onClick={() => navigate('/reader/flooplan')}>View on floorplan</Button>}
            {user?.role === "Root" &&
            <Button className="mt-2 mt-sm-0" onClick={() => setShow(true)}><FontAwesomeIcon icon={faPlus} fixedWidth />Create New</Button>}
          </div>
        </div>
        <Card className="bg-white p-3">
          <CommonTable curTime={curTime.current} ref={childRef} setCountParent={setResultCount} apiName="readers" columns={columns} />
        </Card>
      </Container>
      <Modal show={showModal} onHide={() => setShow(false)}>
        <Modal.Header closeButton><Modal.Title>New Reader</Modal.Title></Modal.Header>
        <Modal.Body>
          <DeviceForm setAsset={updateData} apiName="readers" customerOption={customerOption} deviceOption={deviceOption}/>
        </Modal.Body>
      </Modal>
      <Modal show={showEditModal} onHide={() => setShowEdit(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{showEditModal?.serial}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DeviceDetail onChangeName={(e) => setName(e)} data={showEditModal} type="readers" />
        </Modal.Body>
        <Modal.Footer>
          {user?.editReaders && <Button onClick={() => onSave()}>Save</Button>}
          <Button onClick={() => setShowEdit(false)} variant={'secondary'}>Close</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Readers;