import React from "react";
import QRCode from "qrcode.react";
import { Card } from "react-bootstrap";
import { Codesandbox } from "react-feather";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import useAuth from "../../hooks/useAuth";

// Generate qrcode using given value

const QRCodeComponent = ({ value, type, name, icon }) => {

  const location = useLocation();
  window.locationMe = location
  const { user } = useAuth()

  const downloadQrCode = () => {
    // const canvasImg = document.getElementById('qrCode');
    // const img = new Image();
    // img.src = canvasImg.toDataURL('image/png');
    drawCanvas().then(r => {
      let downLink = document.createElement('a')
      downLink.href =  r;
      downLink.download = value;
      downLink.click()
    })

  }

  const DPR = () => {
    if (window.devicePixelRatio && window.devicePixelRatio > 1) {
      return window.devicePixelRatio;
    } else {
      return 1;
    }
  }

  const parseValue = (value) => {
    return parseInt(value, 10);
  }

  const drawCanvas = async () => {
    const dom = document.getElementById('printHtml');
    const box = window.getComputedStyle(dom);
    const width = parseValue(box.width);
    const height = parseValue(box.height);
    const scaleBy = DPR();
    const canvas = document.createElement('canvas');

    canvas.width = width * scaleBy;
    canvas.height = height * scaleBy;
    canvas.style.width = `${ canvas.width}px`;
    canvas.style.height = `${ canvas.height}px`;
    return await html2canvas(dom, { canvas }).then(canvas => {
      return canvas.toDataURL("image/png");
    });
  }

  return <Card>
    {user?.showQrCodes && <Card.Body className="pt-0 pb-0">
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <h5>
              <Codesandbox size={18} /> QR Code
            </h5>
          </div>
        </Card.Header>
        <Card.Body className="mt-0 pt-0">
          <div className="d-flex flex-column align-items-start">
            <div id={'printHtml'} className="text-center pt-4 pb-3 ps-2 pe-2">
              <QRCode width={'200px'} value={`/scanresult?id=${value}`} id="qrCode"/>
              <div className="mt-2 text-white">{type}: {name}</div>
            </div>
            <span id="down_qacode" className="cursor-pointer"
               onClick={() =>downloadQrCode()}>
              Download
            </span>
          </div>
        </Card.Body>
      </Card>
    </Card.Body>}
  </Card>;
};
export default QRCodeComponent;