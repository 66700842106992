import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import NewObject from "../../components/detail/NewObject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faPlus } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/useAuth";
import { MapPin, Moon, Users } from "react-feather";
import moment from "moment-timezone";
import { NavLink } from "react-router-dom";
import { dateFromNow } from "../../utils/staticMethods";

const People = () => {
  const [resultCount, setResultCount] = useState(0);
  const [showModal, setShow] = useState(false);
  const childRef = useRef();
  const { user } = useAuth();
  const curTime = useRef(moment())

  const columns = [
    {
      Header: "",
      accessor: "icon",
      type: "icon",
      disableSortBy: true
    },
    {
      Header: "Name (Ref)",
      accessor: "compoundName",
      type: "link",
      link: "/people/edit",
      stateId: "id"
    },
    {
      Header: "Last Seen",
      accessor: "lastSeen",
      Cell: (cell) => {
        if (cell.value && cell.value.indexOf('0001-01-01') === -1 ) {
          return <><MapPin width={14} height={14} className="me-1" />{curTime.current ? dateFromNow(cell.value, curTime.current) : dateFromNow(cell.value)}</>
        }
        return <span><Moon width={14} height={14} className="me-1" /> Waiting...</span>
      },
    },
    {
      Header: "Customer",
      accessor: "customerName",
      show: user?.role === "Root",
      disableSortBy: true,
      Cell: cell => (<NavLink
        to={{ pathname: `/customers/edit`, search: `?id=${cell.row?.original?.customerId}` }}>{cell.value}</NavLink>)
    },
    {
      Header: "Hashtags",
      accessor: "hashTags",
      disableSortBy: true,
      Cell: cell => (<div>
        {cell.value && cell.value.map((tag, i) =>  <NavLink key={tag} to={{pathname: '/globalSearch', search: `?searchString=${encodeURIComponent('#' + tag)}`}}><span key={i} className='badge bg-primary me-1 my-1 cursor-pointer'>{tag}</span></NavLink>)}
      </div>)
    }
  ];
  return (
    <React.Fragment>
      <Helmet defer={false}  title="People" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
          <h1 className="h3 mb-0"><Users className='me-2' />People {typeof(resultCount) === 'string' && `(${resultCount})`}</h1>
            {user?.role !== "Root" && <NavLink to={{pathname: '/favourite', search: '?type=people'}}><FontAwesomeIcon className="ms-4 me-2 text-primary cursor-pointer" icon={faHeart} /><span>Favourites</span></NavLink>}
          </div>
          {(user?.role === "Admin" || user?.createPeople) &&
          <Button onClick={() => setShow(true)}><FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />Create New</Button>}
        </div>
        <Card className="bg-white p-3">
          <CommonTable ref={childRef} setCountParent={setResultCount} apiName="people" columns={columns} />
        </Card>
      </Container>
      <NewObject showModal={showModal} closeModal={() => setShow(false)} apiName="people" />
    </React.Fragment>
  );
};
export default People;