import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Table } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { THEME_PALETTE_LIGHT as palette } from "../../constants";
import api from "../../api";
import LoadingSpin from "../loadingSpin";

const BatteryLevel = ({name, width}) => {

  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(false)
  const [dataArr, setDataArr] = useState([])
  const [avg, setAvg] = useState(null)
  const isUmount = useRef(false)

  useEffect(() => {
    getData();
    return () => {
      isUmount.current = true
    }
  }, []);

  const getData = () => {
    setLoading(true)
    let labelArr = [];
    let dataArr = [];
    let arr = [];
    let res = [{label: 'High', value: 2500}, {label: 'Medium ', value: 2100}, {label: 'Low ', value: 400}, {label: 'Exchange ', value: 400}]
    let avg = 0;
    const apiArr = [
      api.get(`reports/assetsbysupply?page=1&limit=10&lowThreshold=13001`),
      api.get(`reports/assetsbysupply?page=1&limit=10&lowThreshold=12001&highThreshold=13000`),
      api.get(`reports/assetsbysupply?page=1&limit=10&lowThreshold=11000&highThreshold=12000`),
      api.get(`reports/assetsbysupply?page=1&limit=10&highThreshold=10999`),
    ]
    Promise.all(apiArr).then(r => {
      r.forEach((item, index) => {
        res[index].value = Number(item.headers['x-total-count'])
        labelArr.push(res[index].label);
        dataArr.push(res[index].value);
        arr.push(res[index]);
        avg += res[index].value;
      })
      setDataArr(arr);
      setAvg(avg);
      let data = {
        labels: arr,
        datasets: [{
          data: dataArr,
          backgroundColor: [
            palette.success,
            palette.warning,
            palette.danger,
            "#E8EAED"
          ],
          borderColor: palette.white
        }]
      }
      setResult(data);
      setLoading(false)
    })
  }

  const options = {
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          let dataset = data.datasets[tooltipItem.datasetIndex];
          let name = data.labels[tooltipItem.index]?.label;
          let currentValue = dataset.data[tooltipItem.index];
          return name + ': ' +currentValue;
        }
      }
    },
    plugins: {
      datalabels: {
        display: false
      }
    },
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    legend: {
      display: false
    }
  };

  return <React.Fragment>
    <Col key={name} lg={(width / 100 * 12).toFixed(0)} className="mb-4">
      <Card className="flex-fill w-100 h-100" style={{ minHeight: "420px" }}>
        <div className="m-3 d-flex justify-content-between align-items-center">
          <h4 style={{ color: "#E0E0E0" }}>Asset Battery Level</h4>
        </div>
        {loading && <div className="d-flex h-100 w-100 justify-content-center align-items-center"><LoadingSpin loading={loading} /></div>}
        {!loading && result && <div>
          <div className="chart chart-xs pb-3">
            <Pie data={result} options={options} />
          </div>
          <div className="ps-3 pe-3">
            <Table>
              <thead>
              <tr>
                <th>Level</th>
                <th className="text-end">Assets</th>
                <th className="text-end">% Total</th>
              </tr>
              </thead>
              <tbody>
              {dataArr.map((item, index) => <tr key={index}>
                <td>
                  <FontAwesomeIcon icon={faSquare} style={{ color: result.datasets[0].backgroundColor[index] }} />{" "}
                  <NavLink to={{pathname: '/batteryLevel', search: `?level=${item.label}`}}>{item.label}</NavLink>
                </td>
                <td className="text-end">{item.value}</td>
                <td className="text-end">{item.value === 0 ? '0' : `${(item.value / avg * 100).toFixed(0)}%`}</td>
              </tr>)}
              </tbody>
            </Table>
          </div>
        </div>}
      </Card>
    </Col>
  </React.Fragment>
}
export default BatteryLevel