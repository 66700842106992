import ReactPaginate from "react-paginate";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import api from "../../api";
import { Button, Col, Container, Modal, Row, Spinner, Table } from "react-bootstrap";
import { useSortBy, useTable } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import SelectHeader from "../SelectHeader";
import LoadingSpin from "../loadingSpin";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import RowSelection from "./RowSelection";
import NotyfContext from "../../contexts/NotyfContext";
import { NavLink } from "react-router-dom";
import {
  AlertCircle,
  BatteryCharging,
  Clock,
  Disc,
  Globe,
  LogIn,
  LogOut,
  Move,
  Package,
  PauseCircle,
  Radio,
  TrendingDown,
  User,
  Wifi
} from "react-feather";
import useAuth from "../../hooks/useAuth";

const tableColumn = {
  floorplans: [
    {
      Header: " ",
      accessor: "",
      disableSortBy: true
    },
    {
      Header: "Name",
      accessor: "floorPlanName",
      Cell: cell => (
        <NavLink to={{pathname: `/floorplanview`, search:`?fId=${cell.row.original.id}`}}>{cell.value || "no name"}</NavLink>
      )
    }
  ],
  users: [
    {
      Header: " ",
      accessor: "",
      disableSortBy: true
    },
    {
      Header: "Name",
      accessor: "compoundName"
    },
    {
      Header: "HashTags",
      accessor: "hashTags",
      disableSortBy: true
    }
  ],
  assets: [
    {
      Header: " ",
      accessor: "",
      disableSortBy: true
    },
    {
      Header: "Name (Ref)",
      accessor: "compoundName"
    },
    {
      Header: "HashTags",
      accessor: "hashTags",
      disableSortBy: true
    }
  ],
  people: [
    {
      Header: " ",
      accessor: "",
      disableSortBy: true
    },
    {
      Header: "Name",
      accessor: "compoundName"
    },
    {
      Header: "HashTags",
      accessor: "hashTags",
      disableSortBy: true
    }
  ],
  zones: [
    {
      Header: " ",
      accessor: "",
      disableSortBy: true
    },
    {
      Header: "Name",
      accessor: "compoundName"
    },
    {
      Header: "HashTags",
      accessor: "hashTags",
      disableSortBy: true
    }
  ],
  rules: [
    {
      Header: " ",
      accessor: "",
      disableSortBy: true
    },
    {
      Header: "Rule Name",
      accessor: "ruleName"
    },
    {
      Header: "Type",
      accessor: "type",
      type: "rule"
    },
    {
      Header: "Status",
      accessor: "status",
      type: "ruleStatus"
    }
  ],
  trackers: [
    {
      Header: " ",
      accessor: "",
      disableSortBy: true
    },
    {
      Header: "Serial#",
      accessor: "serial"
    }
  ],
  readers: [
    {
      Header: " ",
      accessor: "",
      disableSortBy: true
    },
    {
      Header: "Serial#",
      accessor: "serial"
    }
  ],
  tags: [
    {
      Header: " ",
      accessor: "",
      disableSortBy: true
    },
    {
      Header: "Serial#",
      accessor: "serial"
    }
  ],
  contactgroups: [
    {
      Header: " ",
      accessor: "",
      disableSortBy: true
    },
    {
      Header: "Name",
      accessor: "groupName"
    },
    {
      Header: "Emails",
      accessor: "emailAddresses",
      Cell: cell => (
        <span>{cell.value ? cell.value.length : 0}</span>
      )
    },
    {
      Header: "Mobiles",
      accessor: "mobileNumbers",
      Cell: cell => (
        <span>{cell.value ? cell.value.length : 0}</span>
      )
    }
  ]
};

const SelectionTable = ({
                       title,
                       columns,
                       id,
                       entry,
                       buttonText,
                       apiName,
                       currentTab,
                       noDataWarning,
                       errorMessage,
                       selectionStr,
                       apiNew,
                       type = "accessprofiles",
                       filterId = "permissionId",
                       showSelectionTableDefault,
                       onReturnResult,
                       onReturnTotalCount,
                     }) => {
    const notify = useContext(NotyfContext);
    const [data, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [pageCount, setpageCount] = useState(1);
    const [resultCount, setResultCount] = useState(0);
    const [filter, setFilter] = useState("");
    const [limit, setLimit] = useState(10);
    const tableSort= "";
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShow] = useState(false);
    const [curItem, setItem] = useState(null);
    const { user } = useAuth();
    const isUmount = useRef(false);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns, state: { sortBy } } = useTable({
      columns,
      data,
      manualSortBy: false
    }, useSortBy);

    useEffect(() => {
      handleSwitchPage(1);
      return () => {
        isUmount.current = true
      }
    }, []);

    useEffect(() => {
    }, [sortBy]);

    useEffect(() => {
      if (showTable) {
        setShowTable(false);
      }
    }, [currentTab]);

    useEffect(() => {
      if (resultCount) {
        handleSwitchPage(1);
      }
    }, [limit, filter]);

    const filterResults = (query) => {
      setFilter(query);
    };
    const handlePageClick = (data) => {
      handleSwitchPage(data.selected + 1);
    };

    const showAddTable = () => {
      setFilter('')
      setShowTable(true);
    };

    const handleSwitchPage = (cur) => {
      setCurrentPage(cur);
      setLoading(true);
      api.get(`${entry}?page=${cur}&limit=${limit}${filterId ? `&${filterId}=${id}` : ""}&filter=${filter}&sort=${tableSort}`)
        .then(res => {
          if(isUmount.current) return
          const total = res.headers["x-total-count"];
          if (Number(total) === 0 && noDataWarning) {
            noDataWarning(true);
            if (showSelectionTableDefault && filter === "" && tableSort === "") {
              setShowTable(true);
            }
          } else if (Number(total) !== 0 && noDataWarning) {
            noDataWarning(false);
          }
          setResultCount(total);
          onReturnTotalCount && onReturnTotalCount(total)
          setpageCount(Math.ceil(total / limit) || 1);
          setResults(res.data && res.data.filter(item => {
            if(item) {
              return item
            }
            return false
          }) || []);
          onReturnResult && onReturnResult(Number(total), (Math.ceil(Number(total) / limit) || 1), (res.data || []));
          setLoading(false);
        }).catch(() => {
        setLoading(false);
      });
    };

    const onCompleteUpdate = () => {
      onGoBack();
      handleSwitchPage(1);
    };

    const onChangeSelect = (e) => {
      setLimit(e.target.value);
    };

    const onGoBack = () => {
      setShowTable(false);
    };

    const updateOne = (itemId) => {
      setItem(itemId);
      setShow(true);
    };

    const updateRemove = () => {
      setShow(false);
      if (type === "floorplans") {
        api.post(`${type}/${curItem}/zones/unlink/${id}`).then(() => {
          notify.open({
            type: "success",
            message: "Changes Saved"
          });
          setItem(null);
          handleSwitchPage(1);
        });
      } else {
        api.post(`${entry}/unlink/${curItem}`).then(() => {
          notify.open({
            type: "success",
            message: "Changes Saved"
          });
          setItem(null);
          handleSwitchPage(1);
        });
      }
    };

    const getUrl = (cell) => {
      if (entry === "assets") {
        return <Package size={18} />;
      } else if (entry === "people") {
        return <User size={18} />;
      } else if (entry === "zones") {
        return <Disc size={18} />;
      } else if (entry === "trackers") {
        return <Globe size={18} />;
      } else if (entry === "readers") {
        return <Wifi size={18} />;
      } else if (entry === "tags") {
        return <Radio size={18} />;
      } else if (entry === "rules") {
        return <>
          {cell.type === "lowvoltage" && <span><TrendingDown size={18} /></span>}
          {cell.type === "lowbattery" && <span><BatteryCharging size={18} /></span>}
          {cell.type === "speeding" && <span><AlertCircle size={18} /></span>}
          {cell.type === "attendance" && <span><Clock size={18} /></span>}
          {cell.type === "motion" && <span><Move size={18} /></span>}
          {cell.type === "enterzone" && <span><LogIn size={18} /></span>}
          {cell.type === "exitzone" && <span><LogOut size={18} /></span>}
          {cell.type === "pausetracking" && <span><PauseCircle size={18} /></span>}
        </>;
      }
    };

    return (
      <React.Fragment>
        <Modal show={showModal} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Please Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you wish to remove this ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant={'danger'} onClick={() => updateRemove()}>Remove</Button>
            <Button variant={'secondary'} onClick={() => setShow(false)}>Cancel</Button>
          </Modal.Footer>
        </Modal>
        {!showTable ? <Container fluid className="p-0 mt-3">
            <div className="d-flex justify-content-between mb-3">
              <h4 className="text-black">
                {type === "accessprofiles" && title !== "Users" && <FontAwesomeIcon icon={faEye} />}
                {type === "accessprofiles" && title !== "Users" && <span className="ms-2">Visible {title}</span>}
                {type === "accessprofiles" && title !== "Users" && <span className="ms-2">({resultCount})</span>}
                {type === "rules" && <span>{title}</span>}
              </h4>
              {buttonText && (type === "accessprofiles" || (type === "rules" && user?.editRules) || type === "floorplans") &&
              <Button className="text-nowrap" onClick={() => showAddTable()}><FontAwesomeIcon icon={faPlus}
                                                                                              className="me-1" />{buttonText}
              </Button>}
            </div>
            {loading && data.length === 0 ? <Spinner animation="border" /> : (
              <>
                <div>
                  <Row className="d-flex justify-content-between align-items-center">
                    <Col sm={12} md={6} className="d-flex align-items-center dataTables_length">
                      <div className="d-flex align-items-center page-select-container">
                        <span>Show</span>
                        <SelectHeader onChange={onChangeSelect} limit={limit} />
                        <span>entries</span>
                      </div>
                    </Col>
                    <Col sm={12} md={6}
                         className="d-flex dataTables_filter justify-content-lg-end justify-content-sm-center align-items-center">
                      <div>
                        <span>Search:</span>
                        <DebounceInput
                          className="table-search debounceInput-search text-gray"
                          minLength={0}
                          value={filter}
                          debounceTimeout={500}
                          onChange={event => (filterResults(event.target.value))}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                {data.length > 0 ?
                  <Table className="mt-2 dataTable dtr-inline" responsive="sm" striped {...getTableProps()}>
                    <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, columnIndex) => (
                          // Add the sorting props to control sorting. For this example
                          // we can add them into the header props
                          <th key={columnIndex} {...column.getHeaderProps(column.getSortByToggleProps())}
                              className={column.className}>
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            {column.className !== "icon" && <span>
                      {!column.disableSortBy && <>{column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} className="ms-2" />
                      )}</>}
                    </span>}
                          </th>
                        ))}
                      </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {loading && <tr className="table-spinner">
                      <td colSpan={allColumns.length}>
                        <LoadingSpin loading={loading} />
                      </td>
                    </tr>}
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell, index) => {
                            if (cell.column.Header === " ") {
                              return (<td key={index}>
                                <Button variant="danger" disabled={type === "rules" && !user?.editRules}
                                        onClick={() => updateOne(row.original.id || row.original.deviceId)}>Remove</Button>
                              </td>);
                            } else if (cell.column.type === "link") {
                              return <td key={index}>
                                {getUrl(row.original)}
                                {cell.column?.hardLink ? <NavLink className="ms-1" to={{
                                  pathname: cell.column.hardLink,
                                  search: `?id=${row.original[cell.column.stateId]}`
                                }}>{`${cell.value} ${row.original.reference ? `(${row.original.reference})` : ""}`}</NavLink> : <NavLink className="ms-1" to={{
                                  pathname: `${entry.split("/").length === 0 ? `/${entry}/edit` : `/${apiName}/edit`}`,
                                  search: `?id=${row.original[cell.column.stateId]}`
                                }}>{`${cell.value} ${row.original.reference ? `(${row.original.reference})` : ""}`}</NavLink>}
                              </td>;
                            } else if (cell.column.type === "first") {
                              return <td key={index}>
                                {getUrl()}
                                <span className="ms-1">{cell.value}</span>
                              </td>;
                            } else if (cell.column.type === "rule" && cell.value) {
                              return <td key={index}>
                                {cell.value === "lowvoltage" && <span>Low Voltage</span>}
                                {cell.value === "lowbattery" && <span>Low Battery</span>}
                                {cell.value === "speeding" && <span>Speeding</span>}
                                {cell.value === "attendance" && <span>Attendance</span>}
                                {cell.value === "motion" && <span>Motion Alarm</span>}
                                {cell.value === "enterzone" && <span>Zone Entry</span>}
                                {cell.value === "exitzone" && <span>Zone Exit</span>}
                                {cell.value === "pausetracking" && <span>Pause Tracking</span>}
                              </td>;
                            } else {
                              return (
                                <td key={index} {...cell.getCellProps()}>{cell.render("Cell")}</td>
                              );
                            }
                          })}
                        </tr>
                      );
                    })}
                    </tbody>
                  </Table> : <div>
                    <div
                      className="w-100 p-3 text-center mt-3 mb-3 bg-light">{errorMessage ? errorMessage : "No data available in table"}</div>
                  </div>}
                <Row className="d-flex align-items-center">
                  <Col md={5} sm={12}
                       className="dataTables_info">{`Showing ${data.length > 0 ? ((currentPage - 1) * limit + 1) : 0} to ${(currentPage - 1) * limit + data.length} of ${resultCount} entries`}</Col>
                  <Col md={7} sm={12} className="dataTables_paginate d-md-flex justify-content-md-end">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      forcePage={currentPage - 1}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination m-0"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </Col>
                </Row>
              </>)}
          </Container> :
          <RowSelection apiNew={apiNew} type={type} showSelectionTableDefault={showSelectionTableDefault} id={id} goBack={onGoBack}
                        onCompleteUpdate={onCompleteUpdate} entry={apiName}
                        tableColumns={tableColumn[selectionStr ? selectionStr : entry.split("/").length === 0 ? entry : apiName]} />}
      </React.Fragment>
    );
  }
;

export default SelectionTable;