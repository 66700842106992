import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import "../../assets/scss/3-components/_signIn.scss";

import useAuth from "../../hooks/useAuth";
import loginBg from "../../assets/img/loginbg.mov";
import "../../assets/scss/3-components/_video.scss";
import * as queryString from "query-string";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";

function SignIn() {

  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const location = useLocation()
  const { token } = queryString.parse(location.search);
  const notify = useContext(NotyfContext);

  if (!token) {
    navigate('/auth/sign-in');
  }

  useEffect(() => {
    if(isAuthenticated) navigate('/');
  }, [isAuthenticated,]);

  return (
    <React.Fragment>
      <video className="position-fixed min-vw-100 min-vh-100 video-background" autoPlay muted loop id="myVideo">
        <source src={loginBg} type="video/mp4" />
      </video>
      <Helmet defer={false}  title="TRACLOGIK Password reset" />
      <div className="text-center mt-4">
        <h2 className="white-text">Reset Your Password</h2>
      </div>
      <div className="login-container">
        <Formik
          initialValues={{
            submit: false
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string().max(255).required("Password is required"),
            confirmPassword: Yup.string().when("password", {
              is: val => (val && val.length > 0 ? true : false),
              then: Yup.string().oneOf(
                [Yup.ref("password")],
                "Both password need to be the same"
              )
            })
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            let data = {
              password: values.password,
              token: token
            }
            try {
              api.put(`accounts/passwordreset?password=${data.password}&token=${data.token}`, data).then(() => {
                notify.open({
                  type: "success",
                  message: "Changes Saved"
                });
                navigate("/");
              }).catch((error) => {
                let message = error.message || "Something went wrong";
                if(error.message && error.message.indexOf('400') > -1) {
                  message = 'Invalid Credentials.'
                }
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              })
            } catch (error) {
              let message = error.message || "Something went wrong";
              if(error.message && error.message.indexOf('400') > -1) {
                message = 'Invalid Credentials.'
              }
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
            <Form onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}
              <Form.Group className="mb-3">
                <Form.Label className="text-gray">New Password</Form.Label>
                <Form.Control
                  size="lg"
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  value={values.password}
                  isInvalid={Boolean(touched.password && errors.password)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-gray">Confirm New Password</Form.Label>
                <Form.Control
                  size="lg"
                  type="password"
                  name="confirmPassword"
                  placeholder="Enter your password again"
                  value={values.confirmPassword}
                  isInvalid={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.confirmPassword && (
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <div className="mt-3">
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}

export default SignIn;
