import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container, ProgressBar } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { NavLink } from "react-router-dom";

const CagesByDepotPage = () => {

  const [resultCount, setResultCount] = useState(0);

  const getType = (type) => {
    if(type === 'asset') {
      return '/assets/edit'
    } else if(type === 'person') {
      return '/people/edit'
    } else {
      return '/zones/edit'
    }
  }

  const columns = [
    {
      Header: "",
      accessor: "icon",
      type: "icon",
      headerStyle: {
        width: '40px'
      },
      disableSortBy: true
    },
    {
      Header: "Depot",
      accessor: "compoundName",
      width: '200px',
      Cell: cell => (
        <NavLink to={{ pathname: getType(cell.row.original.type), search: `?id=${cell.row.original.id}` }}>{`${cell.value} ${cell.row.original.reference ? `(${cell.row.original.reference})` : ""}`}</NavLink>
      )
    },
    {
      Header: "Cages",
      accessor: "contentsCount",
    },
    {
      Header: "% Total",
      accessor: "contentsPercentage",
      className: 'w-50',
      Cell: cell => (
        <ProgressBar now={cell.value} label={`${cell.value}%`} />
      )
    }
  ];

  return  <React.Fragment>
    <Helmet defer={false}  title="Cages By Depot" />
    <Container fluid className="p-0">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1 className="h3 mb-0">Cages By Depot {typeof(resultCount) === 'string' && `(${resultCount})`}</h1>
      </div>
      <Card className="bg-white p-3">
        <CommonTable showRange={false} secFilterId={"childHashtag"} secId={"cage"}
                     filterId={"parentHashtag"} id={"depot"}  setCountParent={e => {
          setResultCount(e);
        }} apiName={`reports/distribution`} columns={columns} emptyMessage="No cages are at depots sites" />
      </Card>
    </Container>
  </React.Fragment>
}
export default CagesByDepotPage