import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import ResetPassword from "../../components/auth/ResetPassword";
import "../../assets/scss/3-components/_signIn.scss";
import "../../assets/scss/3-components/_video.scss";
import loginBg from "../../assets/img/loginbg.mov";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const ResetPasswordPage = () => {

  const navigate = useNavigate();
  const {isAuthenticated } = useAuth();

  useEffect(() => {
    if(isAuthenticated) navigate('/');
  }, [isAuthenticated,]);

  return (
    <React.Fragment>
      <video className="position-fixed min-vw-100 min-vh-100 video-background" autoPlay muted loop id="myVideo">
        <source src={loginBg} type="video/mp4" />
      </video>
      <Helmet defer={false}  title="Reset Password" />
      <div className="text-center mt-4">
        <h1 className="h2 white-text">Reset password</h1>
        <p className="lead white-text">Enter your email to reset your password.</p>
      </div>

      <Card className="login-container text-gray">
        <div className="m-sm-1">
          <div className="mb-4">Please enter your email address. A password reset email will be sent to this address, if
            it is registered.
          </div>
          <ResetPassword />
        </div>
      </Card>
    </React.Fragment>
  );
};

export default ResetPasswordPage;
