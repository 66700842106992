import React, { useContext, useEffect, useRef, useState } from "react";
import api from "../../api";
import { Button, Card, Dropdown, Modal } from "react-bootstrap";
import { Radio } from "react-feather";
import NotyfContext from "../../contexts/NotyfContext";
import CommonTable from "../tables/CommonTable";
import useAuth from "../../hooks/useAuth";
import { NavLink } from "react-router-dom";

const DeviceTag = ({ id, getTagReady, type, data }) => {
  const [tag, setTag] = useState([]);
  const [showModal, setShow] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [curId, setCurrent] = useState(null);
  const notify = useContext(NotyfContext);
  const childRef = useRef();
  const { user } = useAuth();
  const isUnmount = useRef(false)

  const columns = [
    {
      Header: "",
      accessor: "id",
      minWidth: 60,
      disableSortBy: true,
      Cell: cell => (
        <Button
          variant={cell.row.original.assetId || cell.row.original.personId || cell.row.original.zoneId ? "danger" : "success"}
          onClick={() => onOperate(cell.row.original.assetId || cell.row.original.personId || cell.row.original.zoneId ? "warn" : "edit", cell.value, cell.row.original)}>Link</Button>
      )
    },
    {
      Header: "#Serial",
      accessor: "serial"
    },
    {
      Header: "Model",
      accessor: "model",
      disableSortBy: true,
    },
    {
      Header: "Last Seen",
      accessor: "lastReport",
      type: "dateFromNow"
    },
    {
      Header: "Linked",
      accessor: "elementId",
      type: "linkStatus",
      disableSortBy: true
    }
  ];

  useEffect(() => {
    if (!id) return;
    setShow(false);
    setCurrent(false);
    getTag();
  }, [id]);

  useEffect(() => {
    return () => {isUnmount.current = true}
  }, [])

  const getTag = () => {
    api.get(`${type}/tags/${id}`)
      .then(res => {
        if(isUnmount.current) return
        setTag(res.data);
        getTagReady && getTagReady([res.data]);
      }).catch(() => {
      if(isUnmount.current) return
      setTag(null);
      getTagReady && getTagReady([]);
    });
  };

  const trackerCommand = (id, commandId) => {
    api.post(`tags/${id}/sendcommand/${commandId}`)
      .then(res => {
        notify.open({
          type: "success",
          message: "Command Sent"
        });
      });
  };

  const trackerLink = () => {
    setShow(true);
  };

  const trackerUnlink = (trackerId) => {
    setCurrent(trackerId);
    setShow(true);
  };

  const onOperate = (name, chooseId, data) => {
    if (tag) {
      api.post(`tags/${tag.id}/unlink/${id}`, { params: { parentId: id, id: tag.id } })
        .then(res => {
          notify.open({
            type: "success",
            message: "Changes Saved"
          });
          getTag();
        }).catch(e => {
        notify.open({
          type: "error",
          message: e
        });
      });
      setCurrent(null);
    } else {
      if (name === "edit") {
        api.post(`tags/${chooseId}/link/${id}`, { params: { parentId: id, id: curId } })
          .then(res => {
            notify.open({
              type: "success",
              message: "Changes Saved"
            });
            getTag();
          }).catch(e => {
          notify.open({
            type: "error",
            message: e
          });
        });
        setCurrent(null);
      } else if (name === "warn") {
        setShowWarning(data);
      } else if (name === "relink") {
        api.post(`tags/${chooseId?.id}/unlink/${chooseId?.assetId || chooseId?.personId || chooseId?.zoneId}`)
          .then(res => {
            api.post(`tags/${chooseId?.id}/link/${id}`, { params: { parentId: id, id: curId } })
              .then(res => {
                notify.open({
                  type: "success",
                  message: "Changes Saved"
                });
                getTag();
                setShowWarning(null);
              });
          }).catch(e => {
          notify.open({
            type: "error",
            message: e
          });
        });
      }
    }
    setShow(false);
  };

  return <React.Fragment>
    <Card className="mb-0">
      <Card.Body className="pb-0 pt-0">
        {tag ? <Card>
          <Card.Header>
            <div className="d-flex justify-content-between align-items-center">
              <h5>
                <Radio size={18} /> Tag
              </h5>
              {user?.editTags && user?.role !== "Root" &&
              <Button variant="danger" onClick={() => trackerUnlink(tag.id)}>Unlink tag</Button>}
            </div>
            {user?.role === "Root" ? <div className="mt-2">
              <span>Serial: <NavLink
                to={{ pathname: "/tags/edit", search: `?id=${tag.id}` }}>{tag.serial}</NavLink></span>
            </div> : <div className="mt-2">Serial: {tag.serial}</div>}
            <div>Model: {tag.model}</div>
          </Card.Header>
          <Card.Body>
            {tag.commands && tag.commands.length > 0 && user?.role !== "Root" && <Dropdown>
              <Dropdown.Toggle variant="success">
                Send Command
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {
                  tag.commands.map((command, index) => <Dropdown.Item key={index}
                                                                      onClick={() => trackerCommand(tag.id, command.commandId)}>{command.commandName}</Dropdown.Item>)
                }
              </Dropdown.Menu>
            </Dropdown>}
          </Card.Body>
        </Card> : <Card>
          <Card.Header>
            <div className="d-flex justify-content-between align-items-center">
              <h5>
                <Radio size={18} /> Tag
              </h5>
              {user?.editTags && user?.role !== "Root" &&
              <Button variant="primary" onClick={() => trackerLink()}>Link a Tag</Button>}
            </div>
            <div className="mt-2">TracLogik's tracking tags record the movement of assets and people via our dedicated
              reader devices. Associating a tag with a person or item will automatically enable it for tracking through
              any zones that are equipped with readers.
            </div>
          </Card.Header>
        </Card>}
        <hr />
      </Card.Body>
    </Card>
    <Modal show={showModal} size={curId ? `` : "lg"} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{`${curId ? "Unlink a" : "Link a"} Device`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {tag && <span>Are you sure that you wish to unlink this device from {data?.compoundName}?</span>}
        {!tag && <CommonTable btnClick={onOperate} ref={childRef} apiName="tags" columns={columns} />}
      </Modal.Body>
      <Modal.Footer>
        {tag && <Button variant="danger" onClick={() => onOperate()}>Unlink</Button>}
        <Button variant="secondary" onClick={() => setShow(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
    <Modal show={showWarning} onHide={() => setShowWarning(null)}>
      <Modal.Header closeButton>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure that you wish to link this device to {data?.compoundName}? This will unlink it
          from {showWarning?.assetCompoundName || showWarning?.personCompoundName || showWarning?.zoneCompoundName}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onOperate("relink", showWarning)}>Link</Button>
        <Button variant="secondary" onClick={() => setShowWarning(null)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>;
};
export default DeviceTag;