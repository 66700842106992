import React, { useEffect, useRef, useState } from "react";

import { Card, Col, Table } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import api from "../../api";
import { THEME_PALETTE_LIGHT as palette } from "../../constants";
import { NavLink } from "react-router-dom";

const CageDistribution = ({ width, name }) => {
    const [result, setResult] = useState(null);
    const [dataArr, setDataArr] = useState([]);
    const isUmount = useRef(false);

    useEffect(() => {
      getData();
      return () => {
        isUmount.current = true
      }
    }, []);

    const getData = () => {
      let apiArr = [
        api.get("reports/distributionsummary?childHashtag=cage&parentHashtag=customer"),
        api.get("reports/distributionsummary?childHashtag=cage&parentHashtag=depot"),
        api.get("reports/distributionsummary?childHashtag=cage&parentHashtag=truck")
      ];
      Promise.all(apiArr).then(res => {
        if(isUmount.current) return
        let labelArr = [];
        let dataArr = [];
        let arr = [];
        res.forEach(item => {
          labelArr.push(item.data.parentHashtag);
          dataArr.push(item.data.contentsCount);
          arr.push(item.data);
        });
        setDataArr(arr);
        let data = {
          labels: labelArr,
          datasets: [{
            data: dataArr,
            backgroundColor: [
              palette.primary,
              palette.warning,
              palette.danger,
              "#E8EAED"
            ],
            borderColor: palette.white
          }]
        };
        setResult(data);
      });
    };

    const options = {
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            let dataset = data.datasets[tooltipItem.datasetIndex];
            let name = getTableTitle(data.labels[tooltipItem.index]);
            let currentValue = dataset.data[tooltipItem.index];
            return name + ': ' +currentValue;
          }
        }
      },
      plugins: {
        datalabels: {
          display: false
        }
      },
      maintainAspectRatio: false,
      cutoutPercentage: 70,
      legend: {
        display: false
      }
    };

    const getTableTitle = (e) => {
      if(e === 'truck') {
        return 'In Transit'
      } else if (e === 'depot') {
        return 'Depots'
      } else if (e === 'customer') {
        return 'Customers'
      }
      return ''
    }

    const getUrl = (e) => {
      if(e === 'truck') {
        return '/cagesInTransit'
      } else if (e === 'depot') {
        return '/cagesByDepot'
      } else if (e === 'customer') {
        return '/cagesByCustomer'
      }
      return ''
    }

    return (
      <React.Fragment>
        <Col key={name} lg={(width / 100 * 12).toFixed(0)} className="mb-4">
          <Card className="flex-fill w-100 h-100" style={{ minHeight: "420px" }}>
            <div className="m-3 d-flex justify-content-between align-items-center">
              <h4 style={{ color: "#E0E0E0" }}>Cage Distribution</h4>
            </div>
            {result && dataArr && <div>
              <div className="chart chart-xs pb-3">
                <Pie data={result} options={options} />
              </div>
              <div className="ps-3 pe-3">
                <Table>
                <thead>
                <tr>
                  <th>Location</th>
                  <th className="text-end">Cages</th>
                  <th className="text-end">% Total</th>
                </tr>
                </thead>
                <tbody>
                {dataArr.map((item, index) => <tr key={index}>
                  <td>
                    <FontAwesomeIcon icon={faSquare} style={{ color: result.datasets[0].backgroundColor[index] }} />{" "}
                    <NavLink to={{pathname: getUrl(item.parentHashtag)}}>{getTableTitle(item.parentHashtag)}</NavLink>
                  </td>
                  <td className="text-end">{item.contentsCount}</td>
                  <td className="text-end">{item.contentsPercentage}%</td>
                </tr>)}
                </tbody>
              </Table>
              </div>
            </div>}
          </Card>
        </Col>
      </React.Fragment>
    );
  }
;

export default CageDistribution;
