import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { Mail } from "react-feather";
import { getIcon } from "../../utils/staticMethods";

const Alerts = () => {
  const [resultCount, setResultCount] = useState(0);
  const childRef = useRef();

  const columns = [
    {
      Header: "",
      accessor: "ruleType",
      Cell: cell => (
        <span>{getIcon(cell.value)}</span>
      ),
      disableSortBy: true
    },
    {
      Header: "Rule",
      accessor: "ruleName",
      disableSortBy: true
    },
    {
      Header: "Alert",
      accessor: "message",
      disableSortBy: true
    },
    {
      Header: "Sent",
      accessor: "dateTime",
      type: "date",
      dateFormat: "DD/MM/YY HH:mm:ss"
    }
  ];

  return (
    <React.Fragment>
      <Helmet defer={false}  title="Alert History" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="h3 mb-0"><Mail className={'me-2'} />Alert History {typeof(resultCount) === 'string' && `(${resultCount})`}</h1>
        </div>
        <Card className="bg-white p-3">
          <CommonTable ref={childRef} setCountParent={setResultCount} apiName="alerts" columns={columns} />
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Alerts;