import React, { useRef, useState } from "react";
import { Alert, Button, Card, Form, Modal } from "react-bootstrap";
import api from "../../api";
import { X } from "react-feather";
import { Formik } from "formik";
import moment from "moment-timezone";
import AsyncSelect from "react-select/async";
import useAuth from "../../hooks/useAuth";
import DateTime from "react-datetime";
import { useNavigate } from "react-router-dom";
import CustomOptions from "./CustomOptions";
import { colourStyles } from "../../utils/staticMethods";

const LogTask = ({ handleSuccess }) => {
  const [showModal, setShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [taskList, setTasks] = useState([]);
  const [showDatePicker, setShowPicker] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const formRef = useRef();

  const validate = (values) => {
    const errors = {};
    if (!values.typeId) {
      errors.typeId = "Task type required";
    }
    if (!values.parentId) {
      errors.parentId = "Asset/Zone required";
    }
    if (!values.completed) {
      errors.completed = "Complete date required";
    }
    if (values.completed && moment(values.completed).diff(moment()) > 0) {
      errors.completed = "Please a select a time in the past!";
    }
    formRef.current.errors = errors;
    return errors;
  };

  const taskPromiseOptions = (inputValue) =>
    new Promise((resolve) => {
      api.get("tasks/types?filter=" + inputValue).then(res => {
        if (!loaded) {
          setTasks(res.data);
          setLoaded(true);
        }
        resolve(formatResponse(res.data, 'task'));
      });
    });

  const isDateValid = (cur) => {
    return cur.startOf("days").diff(moment()) < 0;
  };

  const parentPromiseOptions = (inputValue) =>
    new Promise(resolve => {
      let value = inputValue;
      let apiArr = [api.get("assets?limit=10&filter=" + value), api.get("zones?limit=10&filter=" + value)];
      Promise.all(apiArr).then(res => {
        let assets = formatResponse(res[0].data, 'asset');
        let zones = formatResponse(res[1].data, 'zone');
        let asyncOptions = [
          {
            label: "Assets",
            options: assets
          },
          {
            label: "Zones",
            options: zones
          }
        ];
        resolve(asyncOptions);
      });
    });

  const formatResponse = (list, type) => {
    if(Array.isArray(list)) {
      let arr = list.map(item => {
        return { value: item.id, label: item.compoundName || item.name, reference: item.reference, icon: type };
      });
      return arr;
    }
    return []
  };

  return <React.Fragment>
    {user?.logTasks && <Button onClick={() => setShow(true)} className="me-2 mb-2 mt-2 mt-sm-0 mb-sm-0">Log a Task</Button>}
    <Modal show={showModal} onHide={() => {setShow(false); setShowPicker(false)}}>
      <Modal.Header closeButton>
        <Modal.Title>Log a Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-0">
          <Card.Body>
            <Formik
              innerRef={formRef}
              initialValues={{
                statusStrings: [],
                parentId: "",
                childId: user?.id || "",
                typeId: "",
                completed: moment().format()
              }}
              validate={validate}
              onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                  api.post("tasks/log", values)
                    .then(res => {
                      setShow(false);
                      navigate("/completedtask");
                      handleSuccess && handleSuccess();
                    });
                } catch (error) {
                  const message = error.message || "Something went wrong";
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue
                }) => (
                <Form onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert className="my-3" variant="danger">
                      <div className="alert-message">{errors.submit}</div>
                    </Alert>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>Which asset or zone would you like this task to be carried out upon ?</Form.Label>
                    <AsyncSelect components={{Option: CustomOptions}}  onChange={e => {
                      setFieldValue("parentId", e.value);
                    }} defaultInputValue={values.parentName} defaultValue={values.parentId} styles={colourStyles}
                                 cacheOptions defaultOptions
                                 loadOptions={parentPromiseOptions} />
                    {!!touched.parentId && (
                      <div className="text-danger">
                        {errors.parentId}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Which task type would you like to be carried out?</Form.Label>
                    <AsyncSelect components={{Option: CustomOptions}}  onChange={e => {
                      setFieldValue("typeId", e.value);
                      setFieldValue("statusStrings", []);
                    }} defaultInputValue={values.name} defaultValue={values.typeId} styles={colourStyles}
                                 cacheOptions defaultOptions
                                 loadOptions={taskPromiseOptions} />
                    {!!touched.typeId && (
                      <div className="text-danger">
                        {errors.typeId}
                      </div>
                    )}
                  </Form.Group>
                  {values.typeId &&
                  <Form.Group className="mb-3">
                    <Form.Label>Please select completed options</Form.Label>
                    <div className="mt-2">
                      {taskList && Array.isArray(taskList) && taskList.filter(i => i.id === values.typeId)[0].statusStrings.map((str, index) =>
                        <Button variant={"light"} onClick={() => {
                          let arr = values.statusStrings.concat([]);
                          if (arr.indexOf(str) < 0) {
                            arr.push(str);
                          }
                          setFieldValue("statusStrings", arr);
                        }} key={index} className="me-2">{str}</Button>)}
                    </div>
                    <h6 className="mt-3">Completed Options:</h6>
                    {values.statusStrings.length > 0 && <div className="d-flex">
                      {values.statusStrings.map((item, index) => <div key={index} style={{ borderRadius: "2px" }}
                                                                      className="position-relative me-1 pt-1 pb-1 ps-3 pe-3 bg-success text-black">
                        <span>{item}</span>
                        <X size="12" onClick={() => {
                          let arr = values.statusStrings.concat([]);
                          if (arr.indexOf(item) > -1) {
                            arr.splice(arr.indexOf(item), 1);
                          }
                          setFieldValue("statusStrings", arr);
                        }} className="position-absolute text-dark cursor-pointer"
                           style={{ top: "2px", right: "2px" }} />
                      </div>)}
                    </div>}
                    {!!touched.statusStrings && (
                      <div className="text-danger">
                        {errors.statusStrings}
                      </div>
                    )}
                  </Form.Group>}
                  <Form.Group className="mb-3">
                    <Form.Label>Completed</Form.Label>
                    <div className="d-flex align-items-center mb-2">
                      <div className="text-dark">{moment(values.completed).format("DD/MM/YY HH:mm")}</div>
                      <Button variant="link" className="ms-3"
                              onClick={() => setShowPicker(true)}>Edit</Button>
                    </div>
                    {showDatePicker && <DateTime input
                                                 inputProps={{ readOnly: true}}
                                                 className="no-change-date-picker"
                                                 isValidDate={isDateValid}
                                                 initialValue={moment(values.completed)}
                                                 onChange={e => {
                                                   setFieldValue("completed", e.format());
                                                 }}
                                                 dateFormat="DD/MM/YY"
                                                 timeFormat="HH:mm"
                    />}
                    {!!touched.completed && (
                      <div className="text-danger">
                        {errors.completed}
                      </div>
                    )}
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            formRef.current?.handleSubmit();
          }
          }
          variant="primary"
          size="md"
          disabled={formRef.current?.isSubmitting}
        >
          Save
        </Button>
        <Button variant={'secondary'} onClick={() => {
          setShow(false);
          setShowPicker(false);
        }}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>;
};
export default LogTask;