import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import RuleItem from "./RuleItem";
import NewModal from "./NewModal";
import { useLocation, useNavigate } from "react-router-dom";
import * as queryString from "query-string";
import api from "../../api";
import useAuth from "../../hooks/useAuth";

const getRuleName = (type) => {
  if (type === "attendance") {
    return 'Attendance';

  } else if (type === "exitzone") {
    return 'Zone Exit';
  } else if (type === "enterzone") {
    return 'Zone Entry';
  } else if (type === "lowbattery") {
    return 'Low Battery';
  } else if (type === "lowvoltage") {
    return 'Low Voltage';
  } else if (type === "motion") {
    return 'Motion Alarm';
  } else if (type === "speeding") {
    return 'Speeding';
  } else if (type === "pausetracking") {
    return 'Pause Tracking';
  } else if (type === "zonesupervision") {
    return 'Unsupervised Zones';
  }
  return null;
}

const Create = () => {
  const [modalTitle, setModalTitle] = useState('');
  const [modalType, setModalType] = useState(null);
  const location = useLocation();
  const { user } = useAuth();
  const { link, linkId } = queryString.parse(location.search);

  const navigate = useNavigate();

  const onCreate = (type) => {
    setModalTitle(getRuleName(type));
    setModalType(type);
  }
  const onSuccess = (name, data) => {
    if(name === 'create' && link && linkId) {
      api.post(`rules/${data.id}/${link}/link/${linkId}`).then(res => {
        navigate(`/${link}/edit?id=${linkId}&direct=rules`);
        return
      })
    } else {
      navigate(`/rules/edit?id=${data.id}`);
    }
  }

  const onWizards = (type) => {
    if (type !== 'pausetracking') {
      navigate(`/rules/wizard?modalType=${type}${link && linkId ? `&link=${link}&linkId=${linkId}` : ''}`);
    }
  }
  return <React.Fragment >
    <Container className="p-0">
      <Helmet defer={false}  title="New Rule" />
      <h3>New Rule</h3>
      <Row className="show-grid mt-2">
        {user?.showAttendanceRule && <Col xs={12} md={4} className="d-flex">
          <RuleItem onWizards={onWizards} onCreate={onCreate} type="attendance"/>
        </Col>}
        {user?.showZoneEntryRule && <Col xs={12} md={4} className="d-flex">
          <RuleItem onWizards={onWizards} onCreate={onCreate} type="enterzone" />
        </Col>}
        {user?.showZoneExitRule && <Col xs={12} md={4} className="d-flex" >
          <RuleItem onWizards={onWizards} onCreate={onCreate} type="exitzone"/>
        </Col>}
        {user?.showLowBatteryRule && <Col xs={12} md={4} className="d-flex">
          <RuleItem onWizards={onWizards} onCreate={onCreate} type="lowbattery" />
        </Col>}
        {user?.showLowVoltageRule && <Col xs={12} md={4} className="d-flex">
          <RuleItem onWizards={onWizards} onCreate={onCreate} type="lowvoltage" />
        </Col>}
        {user?.showMotionRule && <Col xs={12} md={4} className="d-flex">
          <RuleItem onWizards={onWizards} onCreate={onCreate} type="motion"/>
        </Col>}
        {user?.showSpeedingRule && <Col xs={12} md={4} className="d-flex">
          <RuleItem onWizards={onWizards} onCreate={onCreate} type="speeding" />
        </Col>}
        {user?.showSupervisionRule && <Col xs={12} md={4} className="d-flex">
          <RuleItem onWizards={onWizards} onCreate={onCreate} type="zonesupervision"/>
        </Col>}
      </Row>
    </Container>
    <NewModal modalType={modalType} setModalType={(v) => setModalType(v)} modalTitle={modalTitle} onSuccess={onSuccess}/>
  </React.Fragment>
}
export default Create