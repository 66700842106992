import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/useAuth";
import NewGroups from "./NewGroups";
import { Clipboard } from "react-feather";


const ContactGroups = () => {
  const [resultCount, setResultCount] = useState(0);
  const [showModal, setShow] = useState(false);
  const childRef = useRef();
  const { user } = useAuth();

  const columns = [
    {
      Header: "Name",
      accessor: "groupName",
      type: "link",
      link: "/contactgroups/edit",
      stateId: "id"
    },
    {
      Header: "Emails",
      accessor: "emailAddresses",
      type: "count",
      disableSortBy: true,
      show: user?.showEmailAlerts
    },
    {
      Header: "Mobiles",
      accessor: "mobileNumbers",
      type: "count",
      disableSortBy: true,
      show: user?.showSmsAlerts
    },
    {
      Header: "Teams",
      accessor: "teamsWebHooks",
      type: "count",
      disableSortBy: true,
      show: user?.showTeamsAlerts
    },
  ];


  return (
    <React.Fragment>
      <Helmet defer={false}  title="Contact Groups" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="h3 mb-0"><Clipboard className='me-2' />Contact Groups {typeof(resultCount) === 'string' && `(${resultCount})`}</h1>
          {(user?.role === "Admin" || user?.createContacts) &&
          <Button onClick={() => setShow(true)}><FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />Create New</Button>}
        </div>
        <Card className="bg-white p-3">
          <CommonTable ref={childRef} setCountParent={setResultCount} apiName="contactgroups" columns={columns} />
        </Card>
      </Container>
      <NewGroups showModal={showModal} closeModal={() => setShow(false)} />
    </React.Fragment>
  );
};

export default ContactGroups;