import React, { useEffect, useState } from "react";
import CommonTable from "../../components/tables/CommonTable";
import { Button, Modal } from "react-bootstrap";
import { Disc } from "react-feather";
import AddRecurringModal from "./AddRecurringModal";
import api from "../../api";
import moment from "moment-timezone";
import { NavLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";


const getDuration = (value) => {
  let duration = moment.duration(value, 'seconds');
  let str = ''
  if (duration.hours() === 1) {
    str += duration.hours() + ' hour ';
  } else if(duration.hours() > 1) {
    str += duration.hours() + ' hours ';
  }
  if (duration.minutes() === 1) {
    str += duration.minutes() + ' minute ';
  } else if(duration.minutes() > 1) {
    str += duration.minutes() + ' minutes ';
  }
  if (duration.seconds() === 1) {
    str += duration.seconds() + ' second';
  } else if (duration.seconds() > 1) {
    str += duration.seconds() + 'seconds';
  }
  return str;
}

const Schedule = ({rule, needUpdate, zonesProp}) => {

  const [showRecurring, setShowRecurring] = useState(false);
  const [zones, setZones] = useState([]);
  const [type, setType] = useState(1);
  const [showDelete, setShowDelete] = useState(false);
  const [curId, setCurrent] = useState(false);
  const [curData, setCurData] = useState();
  const {user} = useAuth()

  const columns = [
    {
      Header: "Zone",
      accessor: "compoundName",
      Cell: cell => (
        <span><Disc size={18}/><NavLink to={{pathname: `/zones/edit`, search: `?id=${cell.row.original.elementId}`}} className="ms-1">{cell.value}</NavLink></span>
      )
    },
    {
      Header: "Day",
      accessor: "dayOfWeek",
      minWidth: 80,
    },
    {
      Header: "Start",
      accessor: "start",
      Cell: cell => (
        <span>{moment(cell.value).format("HH:mm")}</span>
      )
    },
    {
      Header: "End",
      accessor: "end",
      Cell: cell => (
        <span>{moment(cell.value).format("HH:mm")}</span>
      )
    },
    {
      Header: "Duration",
      accessor: "duration",
      minWidth: 80,
      Cell: cell => (
        <span>{getDuration(cell.value)}</span>
      )
    },
    {
      Header: "Rate",
      accessor: "rate",
      minWidth: 80,
      Cell: cell => (
        <span>{cell.value.toFixed(2)} {`(${cell.row.original.rateTotal.toFixed(2)})`}</span>
      )
    },
    {
      Header: "",
      accessor: "id",
      type: "action",
      returnParam: "id",
      action: [{name: 'Delete', color: 'danger', disabled: !user?.editRules}],
      disableSortBy: true
    }
  ];

  const columnsDuration = [
    {
      Header: "Trigger",
      accessor: "during",
      minWidth: 80,
      Cell: cell => (
        <span>{cell.value ? 'During' : 'Outside'}</span>
      )
    },
    {
      Header: "Day",
      accessor: "dayOfWeek",
      minWidth: 80,
    },
    {
      Header: "Start",
      accessor: "start",
      Cell: cell => (
        <span>{moment(cell.value).format("HH:mm")}</span>
      )
    },
    {
      Header: "End",
      accessor: "end",
      Cell: cell => (
        <span>{moment(cell.value).format("HH:mm")}</span>
      )
    },
    {
      Header: "Duration",
      accessor: "duration",
      minWidth: 80,
      Cell: cell => (
        <span>{getDuration(cell.value)}</span>
      )
    },
    {
      Header: "",
      accessor: "id",
      type: "action",
      returnParam: "id",
      action: [{name: 'Delete', color: 'danger', disabled: !user?.editRules}],
      disableSortBy: true
    }
  ];

  const columns2 = [
    {
      Header: "Zone",
      accessor: "compoundName",
      Cell: cell => (
        <span><Disc size={18}/>
        <NavLink to={{pathname: `/zones/edit`, search: `?id=${cell.row.original.elementId}`}} className="ms-1">{cell.value}</NavLink>
      </span>
      )
    },
    {
      Header: "Start",
      accessor: "start",
      Cell: cell => (
        <span>{moment(cell.value).format("DD/MM/YYYY HH:mm")}</span>
      )
    },
    {
      Header: "End",
      accessor: "end",
      Cell: cell => (
        <span>{moment(cell.value).format("DD/MM/YYYY HH:mm")}</span>
      )
    },
    {
      Header: "Duration",
      accessor: "duration",
      minWidth: 80,
      Cell: cell => (
        <span>{getDuration(cell.value)}</span>
      )
    },
    {
      Header: "Rate",
      accessor: "rate",
      minWidth: 80,
      Cell: cell => (
        <span>{cell.value.toFixed(2)} {`(${cell.row.original.rateTotal.toFixed(2)})`}</span>
      )
    },
    {
      Header: "",
      accessor: "id",
      type: "action",
      returnParam: "id",
      action: [{name: 'Delete', color: 'danger', disabled: !user?.editRules}],
      disableSortBy: true
    }
  ];

  const columns2Duration = [
    {
      Header: "Trigger",
      accessor: "during",
      minWidth: 80,
      Cell: cell => (
        <span>{cell.value ? 'During' : 'Outside'}</span>
      )
    },
    {
      Header: "Start",
      accessor: "start",
      Cell: cell => (
        <span>{moment(cell.value).format("DD/MM/YYYY HH:mm")}</span>
      )
    },
    {
      Header: "End",
      accessor: "end",
      Cell: cell => (
        <span>{moment(cell.value).format("DD/MM/YYYY HH:mm")}</span>
      )
    },
    {
      Header: "Duration",
      accessor: "duration",
      minWidth: 80,
      Cell: cell => (
        <span>{getDuration(cell.value)}</span>
      )
    },
    {
      Header: "",
      accessor: "id",
      type: "action",
      returnParam: "id",
      action: [{name: 'Delete', color: 'danger', disabled: !user?.editRules}],
      disableSortBy: true
    }
  ];

  const [column, setColumn] = useState(columnsDuration);
  const [column2, setColumn2] = useState(columns2Duration);


  useEffect(() => {
    if (rule && rule.type === 'attendance') {
      setColumn(columns);
      setColumn2(columns2);
      !zonesProp && api.get(`zones?page=1&limit=99999`).then(res => setZones(res.data))
    }
  }, [rule])

  const onSuccess = () => {
    setShowRecurring(false);
    needUpdate()
  }

  const btnClick = (name, id, type) => {
    if(name === 'Delete') {
      setCurrent(id);
      setShowDelete(true);
    } else {
      setType(type);
      setCurrent(id);
      if(type === 1) {
        setCurData(rule.recurringShifts.filter(item => item.id === id)[0])
      } else if (type === 2) {
        setCurData(rule.calendarShifts.filter(item => item.id === id)[0])
      }
      setShowRecurring(true);
    }

  }

  const onDelete = () => {
    api.delete(`shifts/${curId}`).then(() => {
      setShowDelete(false);
      needUpdate();
    })
  }

  return <React.Fragment>
    <div className="d-flex justify-content-between align-items-center mb-3">
      <h4>Recurring Periods</h4>
      {user?.editRules && <Button onClick={() => {setCurData(null); setType(1); setShowRecurring(true)}}>Add a Recurring Period</Button>}
    </div>
    {<CommonTable hideSearch btnClick={(name, id) => btnClick(name, id, 1)} data={rule.recurringShifts}  apiName="shifts" ruleId={rule.id} columns={column} />}
    <div className="mt-5 mb-5 dropdown-divider"/>
    <div className="d-flex justify-content-between align-items-center mb-3">
      <h4>Calendar Periods</h4>
      {user?.editRules && <Button onClick={() => {setCurData(null);setType(2); setShowRecurring(true)}}>Add a Calendar Period</Button>}
    </div>
    {<CommonTable hideSearch btnClick={(name, id) => btnClick(name, id, 2)} data={rule.calendarShifts} apiName="shifts" ruleId={rule.id} columns={column2} />}
    <AddRecurringModal data={curData} type={type} onSuccess={onSuccess} show={showRecurring} onHide={() => setShowRecurring(false)} zones={zones} rule={rule}/>
    <Modal show={showDelete} onHide={() => setShowDelete(false)}>
      <Modal.Header closeButton><Modal.Title>Please Confirm</Modal.Title></Modal.Header>
      <Modal.Body>Are you sure that you wish to delete this Shift?</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => onDelete()}>Delete</Button>
        <Button variant="secondary" onClick={() => setShowDelete(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>
}
export default Schedule